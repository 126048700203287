/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "../../assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function FooterAuthMB(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" ,}}>
        <div className={classes.center} style={{ alignItems: "center" }}>
          Cape Town, South Africa
        </div>

        <div className={classes.center} style={{ alignItems: "center" }}>
          CODING4FINANCE &copy; {1900 + new Date().getYear()}
        </div>

        <div className={classes.center} style={{ alignItems: "center" }}>
          <a
            style={{ textDecorationLine: "none" }}
            href="https://www.albertronindustries.com"
            target="_blank"
          >
            <h4 style={{ color: "#ffbd59", textDecorationLine: "none" }}>
              Developed by Albertron Industries
            </h4>
          </a>
        </div>

        {/* <div className={classes.container} style={{ width: "100%" }}>
          <div className={classes.right}>
            <a
              style={{ textDecorationLine: "none" }}
              href="https://www.albertronindustries.com"
              target="_blank"
            >
              <h4 style={{ color: "#ffbd59", textDecorationLine: "none" }}>
                Developed by Albertron Industries
              </h4>
            </a>
          </div>
        </div> */}
      </div>
    </footer>
  );
}

FooterAuthMB.propTypes = {
  whiteFont: PropTypes.bool,
};
