import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";

import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";

import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/c4f-background.jpg";
import Card from "../../components/Card/Card.js";
import HeaderLinksAdmin from "../../components/Header/HeaderLinksAdmin.js";
import { NavLink, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderAdmin from "../../components/Header/HeaderAdmin.js";
import logo from "../../assets/images/c4f-logo.png";

const useStyles = makeStyles(styles);

export default function AdminHomePage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const authState = useSelector((state) => state);
  console.log("AUTH STATE:", authState);

  // if (!authState.firebase.profile.position) return <Redirect to="/signinadmin" />;
  if (!authState.firebase.auth.uid) {
    return <Redirect to="/signinadmin" />;
  }

  if ( authState.auth.adminLoggedIn ){
  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          height: 700,
        }}
      >
        <HeaderAdmin
          color="white"
          brand="CODING4FINANCE"
          rightLinks={<HeaderLinksAdmin />}
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
          {...rest}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "90%",
          }}
        >
          <NavLink
            style={{ textDecorationLine: "none", margin: 10, width: "40%" }}
            to="/add-admin"
          >
            <Card
              style={{ margin: 10, width: "100%" }}
              className={classNames(classes.main, classes.mainRaised)}
            >
              <div>
                <h3>New Admin</h3>
              </div>
            </Card>
          </NavLink>
          <NavLink
            style={{ textDecorationLine: "none", margin: 10, width: "40%" }}
            to="/add-course"
          >
            <Card
              style={{ margin: 10, width: "100%" }}
              className={classNames(classes.main, classes.mainRaised)}
            >
              <div>
                <h3>Add Course</h3>
              </div>
            </Card>
          </NavLink>
          <NavLink
            style={{ textDecorationLine: "none", margin: 10, width: "40%" }}
            to="/QandA"
          >
            <Card
              style={{ margin: 10, width: "100%" }}
              className={classNames(classes.main, classes.mainRaised)}
            >
              <div>
                <h3>Q and A</h3>
              </div>
            </Card>
          </NavLink>
        </div>
      </div>{" "}
      <Footer />
    </div>
  );}

  return (
    <div
      style={{
        backgroundImage: "url(" + background + ")",
        // backgroundSize: "cover",
        // backgroundPosition: "top center",
        // height: 900,
        // margin: 0,
        // padding: 0,
        // height: "100%"
        // backgroundColor: "red",
        // justifyContent: "center",
        // alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          animationDuration: 2,
          animationName: "pulse",
          animationIterationCount: "infinite",
          animationDirection: "alternate",
        }}
        className="pulsate"
      >
        <h2 style={{ color: "#52EA73", marginRight: 10 }}>Loading ...</h2>
        <img src={logo} alt={""} height={40} />
      </div>
    </div>
  );
}
