import firebase from "../../config/fbConfig";

export const LOAD_ADMINS = "LOAD_ADMINS";

export const startState = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore.collection("admin").onSnapshot((snapshot) => {
      const administrators = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch({ type: "LOAD_ADMINS", admins: administrators });
    });
  };
};

export const loadCourses = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore.collection("courses").onSnapshot((snapshot) => {
      const courses = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch({ type: "LOAD_COURSES", courses: courses });
    });
  };
};

export const createCourse = (title, description, postedBy, amount) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore
      .collection("courses")
      .add({
        title: title,
        description: description,
        postedBy: postedBy,
        postDate: new Date(),
        amount: amount,
      })
      .then(() => {
        alert("A new course has been created!");
        window.location.reload();
      })
      .catch((err) => {});
  };
};

// export const userCourses = () => {
//   return (dispatch) => {

//     dispatch({ type: "SET_USER_COURSES" });
//   };
// }

export const setCourse = (title) => {
  return (dispatch) => {
    const courseT = title;

    dispatch({ type: "SET_COURSE", courseTitle: courseT });
  };
};

export const createLesson = (
  title,
  description,
  video,
  pdf,
  quiz,
  course,
  lessonNumber
) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore
      .collection("lessons")
      .add({
        title: title,
        description: description,
        video: video,
        pdf: pdf,
        quiz: quiz,
        questions: [],
        course: course,
        lessonNumber: lessonNumber,
        postDate: new Date(),
      })
      .then(() => {
        alert("A new lesson has been created!");
        window.location.reload();
      })
      .catch((err) => {
        console.log("ERROR BRO");
      });
  };
};

export const selectLesson = (lesson) => {
  return (dispatch) => {
    dispatch({ type: "SELECT_LESSON", currentLesson: lesson });
  };
};

export const loadLessons = (course) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore.collection("lessons").onSnapshot((snapshot) => {
      const lessons = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const courseLessons = lessons.filter((gr) => gr.course === course);

      dispatch({ type: "LOAD_LESSONS", courseLessons: courseLessons });
    });
  };
};

export const loadQandA = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore.collection("QandA").onSnapshot((snapshot) => {
      const questions = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch({ type: "LOAD_QUESTIONS", allQuestions: questions });
    });
  };
};

export const addQuestion = (question, course, lesson, postedBy) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore
      .collection("QandA")
      .add({
        question: question,
        course: course,
        lesson: lesson,
        datePosted: new Date(),
        postedBy: postedBy,
        answer: "",
      })
      .then(() => {
        alert("The question has been added!");
      })
      .catch((err) => {
        console.log("ERROR BRO");
      });
  };
};

export const loadMeets = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore.collection("meets").onSnapshot((snapshot) => {
      const conversations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch({ type: "LOAD_MEETS", meets: conversations });
    });
  };
};

export const startConversation = (student, messages) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore
      .collection("meets")
      .add({
        student: student,
        messages: [messages],
        recentMessage: messages,
      })
      .then(() => {
        alert("The question has been added!");
      })
      .catch((err) => {
        console.log("ERROR BRO");
      });
  };
};

export const addToConversation = (id, messages, recentMessage) => {
  console.log("At addToConversation, ID:", id);

  return (dispatch) => {
    const firestore = firebase.firestore();

    // firestore.collection("meets").onSnapshot((snapshot) => {
    //   const conversations = snapshot.docs.map((doc) => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }));

    //   const user = conversations.find((gr) => gr.student.email === student);

    firestore
      .collection("meets")
      .doc(id)
      .set(
        {
          messages: messages,
          recentMessage: recentMessage,
        },
        { merge: true }
      )
      .then(() => {
        console.log("Added");
        alert("Message has been added to conversation");
      })
      .catch((err) => {
        console.log("ERROR BRO");
      });
  };
};

export const addAnswer = (id, answer) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore
      .collection("QandA")
      .doc(id)
      .set(
        {
          answer: answer,
        },
        { merge: true }
      )
      .then(() => {
        alert("The answer has been sent!");
      })
      .catch((err) => {
        console.log("ERROR BRO");
      });
  };
};

export const newAdmin = (adminEmail) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore.collection("users").onSnapshot((snapshot) => {
      const accounts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const user = accounts.find((gr) => gr.email === adminEmail);

      user &&
        firestore.collection("users").doc(user.id).set(
          {
            position: "admin",
          },
          { merge: true }
        );
      user &&
        firestore.collection("admin").add({
          firstName: user.firstName,
          lastName: user.lastname,
          email: user.email,
          position: "admin",
          loaction: "",
        });
    });
  };
};

export const deleteWait = (userId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("waitingList")
      .doc(userId)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  };
};

export const addToSession = (student) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("sessionList")
      .add({
        firstName: student.firstName,
        lastName: student.lastName,
        email: student.email,
        addDate: new Date(),
      })
      .then(function () {
        console.log("Document successfully added");
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  };
};
