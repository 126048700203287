import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
// @material-ui/core components

// @material-ui/icons

// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";

import HeaderLinksM from "../../components/Header/HeaderLinksM.js";

import background from "../../assets/images/c4f-background.jpg";
import Card from "../../components/Card/Card.js";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import {
  addToConversation,
  loadMeets,
  startConversation,
} from "../../store/actions/classActions.js";
import moment from "moment";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import { Button } from "@material-ui/core";

export default function MeetInstructor(props) {
  const [message, setMessage] = useState();
  const [meetId, setMeetId] = useState();
  // const [userMeets, setUserMeets] = useState();

  // const [meets, setMeets] = useState([]);

  const { ...rest } = props;
  const authState = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadMeets());
  }, [dispatch]);

  const loadedMeets = useSelector((state) => state.class.meets);
  console.log("loadedMeets", loadedMeets);

  const inputChangeM = (value) => {
    setMessage(value.target.value);
  };
  const user = authState.firebase.profile;
  console.log("user:", user);

  const sendMessage = () => {
    if (userMeets) {
      console.log("ADD TO CONVO")
      const time = new Date();

      const status = "student";
      // const message = message.concat(userMeets.messages);
      const meet = [{ message, time, user, status }];

      const meets = meet.concat(userMeets.messages);
      const meetObj = { message, time, user, status };

      dispatch(addToConversation(userMeets.id, meets, meetObj));
    } else {
      console.log('START CONVO')
      const time = new Date();

      const status = "student";
      const meet = { message, time, user, status };

      dispatch(startConversation(user, meet, meet));
    }
    setMessage("");
  };
  
  const userMeets = loadedMeets.find((gr) => gr.student.email === user.email);

  if (!authState.firebase.auth.uid) return <Redirect to="/" />;
  return (
    <div
      style={{
        backgroundImage: "url(" + background + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
        height: "100%",
      }}
    >
      <div style={{ height: "90%" }}>
        <Header
          color="white"
          brand="CODING4FINANCE"
          rightLinks={<HeaderLinksM />}
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
          {...rest}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <GridContainer style={{ width: "100%" }}>
            <GridItem
              style={{ display: "flex", justifyContent: "center" }}
              xs={12}
              sm={12}
              md={6}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: 600,
                  overflowY: "scroll",
                  width: "70%",
                }}
                className="example"
              >
                <h1 style={{ color: "white" }}>
                  Message the course instructor and setup a meeting if you would
                  like live assistance.
                </h1>
              </div>
            </GridItem>
            <GridItem
              style={{ justifyContent: "left", height: 700 }}
              xs={12}
              sm={12}
              md={6}
            >
              <div
                style={{
                  backgroundColor: "white",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: 550,
                    overflowY: "scroll",
                  }}
                  className="example"
                >
                  {loadedMeets && userMeets ? (
                    userMeets.messages
                      .sort((a, b) => b.time.toDate() - a.time.toDate())
                      .map((question) => {
                        console.log("");

                        const userID = userMeets.id;
                        console.log("User ID", userID);
                
                        if (question.status === "admin") {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "60%",
                              }}
                            >
                              <Card
                                style={{
                                  backgroundColor: "#C3FFC1",
                                  margin: 9,
                                  textAlign: "right",
                                  padding: 7,
                                }}
                              >
                                <p>{question.message}</p>
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: 11,
                                    margin: 1,
                                  }}
                                ></p>
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: 11,
                                    margin: 1,
                                  }}
                                >
                                  Asked by: {question.user.email}
                                </p>
                                <p
                                  style={{
                                    fontSize: 9,
                                    color: "grey",
                                    margin: 1,
                                  }}
                                >
                                  {moment(question.time.toDate()).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </p>
                              </Card>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "60%",
                              }}
                            >
                              <Card
                                style={{
                                  backgroundColor: "#E3F9EB",
                                  margin: 9,
                                  textAlign: "left",
                                  padding: 7,
                                }}
                              >
                                <p>{question.message}</p>
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: 11,
                                    margin: 1,
                                  }}
                                ></p>
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: 11,
                                    margin: 1,
                                  }}
                                >
                                  Asked by: {question.user.email}
                                </p>
                                <p
                                  style={{
                                    fontSize: 9,
                                    color: "grey",
                                    margin: 1,
                                  }}
                                >
                                  {moment(question.time.toDate()).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </p>
                              </Card>
                              {/* <Card
                            style={{
                              backgroundColor: "#C3FFC1",
                              margin: 9,
                              textAlign: "right",
                              padding: 7,
                            }}
                          >
                            <p>{question.answer}</p>
                          </Card> */}
                            </div>
                          );
                        }
                      })
                  ) : (
                    <div></div>
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "60%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CustomInput
                      labelText="Enter message"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: message,
                        onChange: (text) => {
                          inputChangeM(text);
                        },
                        type: "text",
                        multiline: true,
                      }}
                    />{" "}
                  </div>
                  <Button
                    onClick={() => {
                      sendMessage();
                      console.log("length",loadedMeets.length)
                      if (userMeets)
                      console.log("user meets")
                      setMeetId()
                    }}
                    style={{ backgroundColor: "#52EA73", marginLeft: 30 }}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>{" "}
      <Footer />
    </div>
  );
}
