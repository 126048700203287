import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components

import Footer from "../../components/Footer/Footer.js";

import Parallax from "../../components/Parallax/Parallax.js";

import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/c4f-background.jpg";
import Card from "../../components/Card/Card.js";
import HeaderLinksAdmin from "../../components/Header/HeaderLinksAdmin.js";
import {  Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button,  InputAdornment } from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import { Email, People } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as authActions from "../../store/actions/authActions";
import HeaderAdmin from "../../components/Header/HeaderAdmin.js";
import logo from "../../assets/images/c4f-logo.png";

const useStyles = makeStyles(styles);

export default function AddAdmin(props) {

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { ...rest } = props;

  const user = { firstName, lastName, email, password };
  const inputChangeF = (value) => {
    setFirstName(value.target.value);
  };

  const inputChangeL = (value) => {
    setLastName(value.target.value);
  };

  const inputChangeE = (value) => {
    setEmail(value.target.value);
  };

  const inputChangeP = (value) => {
    setPassword(value.target.value);
  };

  const togglePassword = () => {
    passwordType === "text"
      ? setPasswordType("password")
      : setPasswordType("text");
  };

  const authHandler = async () => {
    let action;

    action = authActions.signupAdmin(user);
    setError(null);
    setIsLoading(true);
    try {
      await dispatch(action);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  const authState = useSelector((state) => state);
  if (!authState.firebase.auth.uid ){return <Redirect to="/signinadmin" />} ;
  if ( authState.auth.adminLoggedIn){
  return (
    <div>
             <div
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          height:750
        }}
      >
       
        <HeaderAdmin
          color="white"
          brand="CODING4FINANCE"
          rightLinks={<HeaderLinksAdmin />}
     
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
          {...rest}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Card
            style={{ width: "50%", padding: 15 }}
            className={classNames(classes.main, classes.mainRaised)}
          >
            <h3>Add New Admin</h3>
            <h4>Please signup the user below:</h4>
            <CustomInput
              labelText="First Name..."
              id="first"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (text) => inputChangeF(text),
                type: "text",
                endAdornment: (
                  <InputAdornment position="end">
                    <People className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
              }}
            />{" "}
            <CustomInput
              labelText="Last Name..."
              id="lastName"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (text) => {
                  inputChangeL(text);
                },
                type: "text",
                endAdornment: (
                  <InputAdornment position="end">
                    <People className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
            />
            <CustomInput
              labelText="Email..."
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (text) => {
                  inputChangeE(text);
                },
                type: "email",
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
              }}
            />
            <CustomInput
              labelText="Password..."
              id="Password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (text) => {
                  inputChangeP(text);
                },
                type: passwordType,
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      onClick={() => {
                        togglePassword();
                      }}
                    >
                      <VisibilityIcon />
                    </div>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
            />
            <Button
              simple
              style={{ color: "white", backgroundColor: "#52EA73" }}
              size="lg"
              onClick={authHandler}
            >
              Sign Up Admin
            </Button>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );}


  return (
    <div
      style={{
        backgroundImage: "url(" + background + ")",
        // backgroundSize: "cover",
        // backgroundPosition: "top center",
        // height: 900,
        // margin: 0,
        // padding: 0,
        // height: "100%"
        // backgroundColor: "red",
        // justifyContent: "center",
        // alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          animationDuration: 2,
          animationName: "pulse",
          animationIterationCount: "infinite",
          animationDirection: "alternate",
        }}
        className="pulsate"
      >
        <h2 style={{ color: "#52EA73", marginRight: 10 }}>Loading ...</h2>
        <img src={logo} alt={""} height={40} />
      </div>
    </div>
  );
}
