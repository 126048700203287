import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components

import Footer from "../../components/Footer/Footer.js";

import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/c4f-background.jpg";
import Card from "../../components/Card/Card.js";
import HeaderLinksAdmin from "../../components/Header/HeaderLinksAdmin.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import {
  createLesson,
  loadLessons,
  selectLesson,
} from "../../store/actions/classActions.js";
import firebase from "../../config/fbConfig";
import HeaderAdmin from "../../components/Header/HeaderAdmin.js";
import logo from "../../assets/images/c4f-logo.png";

const useStyles = makeStyles(styles);

export default function AddLesson(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState("");
  const [pdf, setPdf] = useState("");
  const [quiz, setQuiz] = useState("");
  const [lessonNo, setLessonNo] = useState([]);
  const [fileV, setFileV] = useState("");
  const [fileRefV, setFileRefV] = useState("");
  const [fileP, setFileP] = useState("");
  const [fileRefP, setFileRefP] = useState("");
  const [videoBtn, setVideoBtn] = useState(true);
  const [pdfBtn, setPdfBtn] = useState(true);
  const [videoBtnTitle, setVideoBtnTitle] = useState("Upload Video");
  const [pdfBtnTitle, setPdfBtnTitle] = useState("Upload PDF");

  const classes = useStyles();
  const { ...rest } = props;
  const dispatch = useDispatch();

  const inputChangeT = (value) => {
    setTitle(value.target.value);
  };
  const inputChangeD = (value) => {
    setDescription(value.target.value);
  };

  const inputChangeQ = (value) => {
    setQuiz(value.target.value);
  };
  const inputChangeL = (value) => {
    setLessonNo(value.target.value);
  };

  const uploadVideo = async (e) => {
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(file.name);

    setFileV(file);
    setFileRefV(fileRef);
    setVideoBtn(false);
    setVideoBtnTitle("Upload Video");
  };

  const uploadPdf = async (e) => {
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(file.name);

    setFileP(file);
    setFileRefP(fileRef);
    setPdfBtn(false);
    setPdfBtnTitle("Upload PDF");
  };

  const submitVideo = async () => {
    setVideoBtnTitle("Uploading ...");
    setVideoBtn(true);
    await fileRefV.put(fileV);
    const vid = await fileRefV.getDownloadURL();
    setVideo(vid);

    setVideoBtnTitle("Uploaded");
  };

  const submitPdf = async () => {
    setPdfBtnTitle("Uploading ...");
    setPdfBtn(true);
    await fileRefP.put(fileP);
    const pdfFile = await fileRefP.getDownloadURL();
    setPdf(pdfFile);

    setPdfBtnTitle("Uploaded");
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const currentCourse = useSelector((state) => state.class.courseTitle);

  useEffect(() => {
    dispatch(loadLessons(currentCourse));
  }, [dispatch, currentCourse]);

  const createNewLesson = (props) => {
    if (title === "" || description === "" || video === "" || lessonNo === "") {
      alert(
        "Please make sure the title, description, video and lesson number have been filled in."
      );
    } else {
      dispatch(
        createLesson(
          title,
          description,
          video,
          pdf,
          quiz,
          currentCourse,
          lessonNo
        )
      );
    }
  };

  const lessonsList = useSelector((state) => state.class.lessons);
  console.log("Lessons:", lessonsList);
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const authState = useSelector((state) => state);
  if (!authState.firebase.auth.uid) {
    return <Redirect to="/signinadmin" />;
  }
  if (authState.auth.adminLoggedIn) {
    return (
      <div>
        {" "}
        <div
          className={classNames(classes.filter)}
          style={{
            backgroundImage: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            backgroundBlendMode: "darken",
          }}
        >
          {" "}
          <HeaderAdmin
            color="white"
            brand="CODING4FINANCE"
            rightLinks={<HeaderLinksAdmin />}
            changeColorOnScroll={{
              height: 200,
              color: "white",
            }}
            {...rest}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <GridContainer style={{ width: "100%" }}>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                {/* <h2 style={{ color: "white" }}>Add Lesson</h2> */}
                <Card style={{ padding: 20, width: "90%" }}>
                  <div>
                    <div>
                      <CustomInput
                        labelText="Lesson Title"
                        id="title"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (text) => {
                            inputChangeT(text);
                          },
                          type: "title",
                        }}
                        required
                      />
                      <CustomInput
                        labelText="Lesson Number"
                        id="number"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (text) => {
                            inputChangeL(text);
                          },
                          type: "number",
                        }}
                      />
                      <CustomInput
                        labelText="Lesson Description"
                        id="description"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (text) => {
                            inputChangeD(text);
                          },
                          type: "title",
                          multiline: true,
                        }}
                      />

                      <div
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <h4 style={{ marginBottom: 0 }}>Video</h4>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <CustomInput
                          labelText="Video"
                          id="video"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (value) => {
                              uploadVideo(value);
                            },
                            type: "file",
                          }}
                        />
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            disabled={videoBtn}
                            // style={{
                            //   backgroundColor: "#52EA73",
                            //   color: "black",
                            //   width: "70%",
                            //   // margin: 10,
                            //   marginLeft: 20,
                            //   padding: 7,
                            // }}
                            onClick={() => {
                              submitVideo();
                            }}
                          >
                            {videoBtnTitle}
                          </Button>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <h4 style={{ marginBottom: 0 }}>PDF</h4>
                      </div>

                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <CustomInput
                          labelText="PDF"
                          id="pdf"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (value) => {
                              uploadPdf(value);
                            },
                            type: "file",
                          }}
                        />
                        <div
                          style={{
                            width: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            disabled={pdfBtn}
                            className="mt-4"
                            // style={{

                            //   // backgroundColor: "#52EA73",
                            //   color: "black",
                            //   width: "70%",
                            //   // margin: 10,
                            //   marginLeft: 20,
                            //   padding: 7,
                            // }}
                            onClick={() => {
                              submitPdf();
                            }}
                          >
                            {pdfBtnTitle}
                          </Button>
                        </div>
                      </div>
                      <CustomInput
                        labelText="Quiz"
                        id="quiz"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (text) => {
                            inputChangeQ(text);
                          },
                          type: "url",
                        }}
                      />
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <Button
                        style={{ backgroundColor: "#52EA73", color: "black" }}
                        onClick={() => {
                          createNewLesson();
                        }}
                      >
                        Create Lesson
                      </Button>
                    </div>
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 style={{ color: "white" }}>Lessons</h2>
                <div
                  style={{
                    height: 500,
                    overflowY: "scroll",
                    borderRadius: 10,
                  }}
                  className="example"
                >
                  {lessonsList &&
                    lessonsList.map((lsn, key) => {
                      return (
                        <NavLink
                          onClick={() => {
                            dispatch(selectLesson(lsn.lessonNumber));
                          }}
                          to="/lesson-preview"
                          style={{ textDecorationLine: "none", margin: 10 }}
                        >
                          <Card
                            onClick={() => {
                              dispatch(selectLesson(lsn.lessonNumber));
                            }}
                          >
                            <h4>{lsn.title}</h4>
                            <p>This is lesson number-{lsn.lessonNumber}</p>
                          </Card>
                        </NavLink>
                      );
                    })}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>{" "}
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: "url(" + background + ")",
        // backgroundSize: "cover",
        // backgroundPosition: "top center",
        // height: 900,
        // margin: 0,
        // padding: 0,
        // height: "100%"
        // backgroundColor: "red",
        // justifyContent: "center",
        // alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          animationDuration: 2,
          animationName: "pulse",
          animationIterationCount: "infinite",
          animationDirection: "alternate",
        }}
        className="pulsate"
      >
        <h2 style={{ color: "#52EA73", marginRight: 10 }}>Loading ...</h2>
        <img src={logo} alt={""} height={40} />
      </div>
    </div>
  );
}
