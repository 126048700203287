import authReducer from "./authReducer";

import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import classReducer from "./classReducers";

const rootReducer = combineReducers({
  auth: authReducer,

  firestore: firestoreReducer,
  firebase: firebaseReducer,
  class: classReducer,
});

export default rootReducer;
