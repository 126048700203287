import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes

// @material-ui/icons

// core components
import Footer from "../../components/Footer/Footer.js";

import background from "../../assets/images/c4f-background.jpg";
import Card from "../../components/Card/Card.js";
import HeaderLinksAdmin from "../../components/Header/HeaderLinksAdmin.js";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderAdmin from "../../components/Header/HeaderAdmin.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import {
  addToConversation,
  loadMeets,
} from "../../store/actions/classActions.js";
import moment from "moment";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import { Button } from "@material-ui/core";
import HeaderLinksAdminM from "../../components/Header/HeaderLinksAdminM.js";
import logo from "../../assets/images/c4f-logo.png";

export default function MeetupsPage(props) {
  const [message, setMessage] = useState();
  const [convo, setConvo] = useState();
  const [meetId, setMeetId] = useState();

  const authState = useSelector((state) => state);
  const { ...rest } = props;

  const dispatch = useDispatch();

  const setConvos = (student) => {
    setConvo(student.messages);
  };

  useEffect(() => {
    dispatch(loadMeets());
  }, [dispatch]);

  const user = authState.firebase.profile;

  const sendMessage = (id) => {
    const time = new Date();

    const status = "admin";
    // const message = message.concat(userMeets.messages);
    const meet = [{ message, time, user, status }];
    const meetObj = { message, time, user, status };

    const meets = meet.concat(convo);

    dispatch(addToConversation(id, meets, meetObj));
    setMessage("");
    setConvo();
  };

  const inputChangeM = (value) => {
    setMessage(value.target.value);
  };

  const loadedMeets = useSelector((state) => state.class.meets);

  const ansMeets = loadedMeets.filter(
    (gr) => gr.recentMessage.status === "admin"
  );
  const unAnsMeets = loadedMeets.filter(
    (gr) => gr.recentMessage.status === "student"
  );

  if (!authState.firebase.auth.uid) {
    return <Redirect to="/signinadmin" />;
  }

  if (window.innerWidth >= 640 && authState.auth.adminLoggedIn) {
    return (
      <div>
        <div
          style={{
            backgroundImage: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            height: 750,
          }}
        >
          <HeaderAdmin
            color="white"
            brand="CODING4FINANCE"
            rightLinks={<HeaderLinksAdminM />}
            changeColorOnScroll={{
              height: 200,
              color: "white",
            }}
            {...rest}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <GridContainer style={{ width: "100%" }}>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <div style={{ height: 300 }}>
                  <h2 justify="left" style={{ color: "white" }}>
                    Unanswered Conversations
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: 280,
                      overflowY: "scroll",
                    }}
                    className="example"
                  >
                    {unAnsMeets &&
                      unAnsMeets
                        .sort(
                          (a, b) => (a, b) =>
                            b.recentMessage.time.seconds * 1000 -
                            a.recentMessage.time.seconds * 1000
                        )
                        .map((question) => {
                          const meetID = question.id;
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "60%",
                              }}
                            >
                              <Card
                                style={{
                                  backgroundColor: "#FA706C",
                                  margin: 0,
                                  textAlign: "center",
                                  padding: 7,
                                  marginBottom: 8,
                                }}
                                onClick={() => {
                                  setConvos(question);
                                  setMeetId(meetID);
                                }}
                              >
                                <h4>
                                  {question.student.firstName}{" "}
                                  {question.student.lastname}
                                </h4>
                              </Card>
                            </div>
                          );
                        })}
                  </div>
                </div>
                <div style={{ height: 300 }}>
                  <h2 style={{ color: "white" }}>Answered Conversations</h2>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: 280,
                      overflowY: "scroll",
                    }}
                    className="example"
                  >
                    {ansMeets &&
                      ansMeets
                        .sort(
                          (a, b) =>
                            b.recentMessage.time.seconds * 1000 -
                            a.recentMessage.time.seconds * 1000
                        )
                        .map((question) => {
                          const meetID = question.id;
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "60%",
                              }}
                            >
                              <Card
                                style={{
                                  backgroundColor: "#E3F9EB",
                                  margin: 0,
                                  textAlign: "center",
                                  padding: 7,
                                  marginBottom: 8,
                                }}
                                onClick={() => {
                                  setConvos(question);
                                  setMeetId(meetID);
                                }}
                              >
                                <h4>
                                  {question.student.firstName}{" "}
                                  {question.student.lastname}
                                </h4>
                              </Card>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left", height: 700 }}
                xs={12}
                sm={12}
                md={6}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: 550,
                      overflowY: "scroll",
                    }}
                    className="example"
                  >
                    {!convo ? (
                      <div>
                        <h3>Please select a conversation</h3>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {convo ? (
                      convo
                        .sort(
                          (a, b) =>
                            b.time.seconds * 1000 - a.time.seconds * 1000
                        )
                        .map((question) => {
                          if (question.status === "admin") {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "60%",
                                }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: "#C3FFC1",
                                    margin: 9,
                                    textAlign: "right",
                                    padding: 7,
                                  }}
                                >
                                  <p>{question.message}</p>
                                  <p
                                    style={{
                                      color: "grey",
                                      fontSize: 11,
                                      margin: 1,
                                    }}
                                  ></p>
                                  <p
                                    style={{
                                      color: "grey",
                                      fontSize: 11,
                                      margin: 1,
                                    }}
                                  >
                                    Answered by: {question.user.email}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 9,
                                      color: "grey",
                                      margin: 1,
                                    }}
                                  >
                                    {moment(
                                      new Date(question.time.seconds * 1000)
                                    ).calendar()}
                                  </p>
                                </Card>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "60%",
                                }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: "#E3F9EB",
                                    margin: 9,
                                    textAlign: "left",
                                    padding: 7,
                                  }}
                                >
                                  <p>{question.message}</p>
                                  <p
                                    style={{
                                      color: "grey",
                                      fontSize: 11,
                                      margin: 1,
                                    }}
                                  ></p>
                                  <p
                                    style={{
                                      color: "grey",
                                      fontSize: 11,
                                      margin: 1,
                                    }}
                                  >
                                    Asked by: {question.user.email}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 9,
                                      color: "grey",
                                      margin: 1,
                                    }}
                                  >
                                    {moment(
                                      new Date(question.time.seconds * 1000)
                                    ).calendar()}
                                  </p>
                                </Card>
                              </div>
                            );
                          }
                        })
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "60%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <CustomInput
                        labelText="Enter message"
                        id="message"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: message,
                          onChange: (text) => {
                            inputChangeM(text);
                          },
                          type: "text",
                          multiline: true,
                        }}
                      />{" "}
                    </div>
                    <Button
                      disabled={convo ? false : true}
                      onClick={() => {
                        sendMessage(meetId);
                      }}
                      style={{ backgroundColor: "#52EA73", marginLeft: 30 }}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>{" "}
        <Footer />
      </div>
    );
  } else if (window.innerWidth < 640 && authState.auth.adminLoggedIn) {
    return (
      <div>
        <div
          style={{
            backgroundImage: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            height: "100%",
          }}
        >
          <HeaderAdmin
            color="white"
            brand="CODING4FINANCE"
            rightLinks={<HeaderLinksAdminM />}
            changeColorOnScroll={{
              height: 200,
              color: "white",
            }}
            {...rest}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <GridContainer style={{ width: "100%" }}>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <div style={{ height: 300 }}>
                  <h2 justify="left" style={{ color: "white" }}>
                    Unanswered Conversations
                  </h2>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: 280,
                      overflowY: "scroll",
                    }}
                    className="example"
                  >
                    {unAnsMeets &&
                      unAnsMeets
                        .sort(
                          (a, b) =>
                            b.recentMessage.time.seconds * 1000 -
                            a.recentMessage.time.seconds * 1000
                        )
                        .map((question) => {
                          const meetID = question.id;

                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "60%",
                              }}
                            >
                              <Card
                                style={{
                                  backgroundColor: "#FA706C",
                                  margin: 0,
                                  textAlign: "center",
                                  padding: 7,
                                  marginBottom: 8,
                                }}
                                onClick={() => {
                                  setConvos(question);
                                  setMeetId(meetID);
                                }}
                              >
                                <h4>
                                  {question.student.firstName}{" "}
                                  {question.student.lastname}
                                </h4>
                              </Card>
                            </div>
                          );
                        })}
                  </div>
                </div>
                <div style={{ height: 300 }}>
                  <h2 style={{ color: "white" }}>Answered Conversations</h2>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: 280,
                      overflowY: "scroll",
                    }}
                    className="example"
                  >
                    {ansMeets &&
                      ansMeets
                        .sort(
                          (a, b) =>
                            b.recentMessage.time.seconds * 1000 -
                            a.recentMessage.time.seconds * 1000
                        )
                        .map((question) => {
                          const meetID = question.id;
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "60%",
                              }}
                            >
                              <Card
                                style={{
                                  backgroundColor: "#E3F9EB",
                                  margin: 0,
                                  textAlign: "center",
                                  padding: 7,
                                  marginBottom: 8,
                                }}
                                onClick={() => {
                                  setConvos(question);
                                  setMeetId(meetID);
                                }}
                              >
                                <h4>
                                  {question.student.firstName}{" "}
                                  {question.student.lastname}
                                </h4>
                              </Card>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left", height: 700 }}
                xs={12}
                sm={12}
                md={6}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: 550,
                      overflowY: "scroll",
                    }}
                    className="example"
                  >
                    {!convo ? (
                      <div>
                        <h3>Please select a conversation</h3>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {convo ? (
                      convo
                        .sort(
                          (a, b) =>
                            b.time.seconds * 1000 - a.time.seconds * 1000
                        )
                        .map((question) => {
                          if (question.status === "admin") {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "60%",
                                }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: "#C3FFC1",
                                    margin: 9,
                                    textAlign: "right",
                                    padding: 7,
                                  }}
                                >
                                  <p>{question.message}</p>
                                  <p
                                    style={{
                                      color: "grey",
                                      fontSize: 11,
                                      margin: 1,
                                    }}
                                  ></p>
                                  <p
                                    style={{
                                      color: "grey",
                                      fontSize: 11,
                                      margin: 1,
                                    }}
                                  >
                                    Answered by: {question.user.email}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 9,
                                      color: "grey",
                                      margin: 1,
                                    }}
                                  >
                                    {moment(
                                      new Date(question.time.seconds * 1000)
                                    ).calendar()}
                                  </p>
                                </Card>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "60%",
                                }}
                              >
                                <Card
                                  style={{
                                    backgroundColor: "#E3F9EB",
                                    margin: 9,
                                    textAlign: "left",
                                    padding: 7,
                                  }}
                                >
                                  <p>{question.message}</p>
                                  <p
                                    style={{
                                      color: "grey",
                                      fontSize: 11,
                                      margin: 1,
                                    }}
                                  ></p>
                                  <p
                                    style={{
                                      color: "grey",
                                      fontSize: 11,
                                      margin: 1,
                                    }}
                                  >
                                    Asked by: {question.user.email}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 9,
                                      color: "grey",
                                      margin: 1,
                                    }}
                                  >
                                    {moment(
                                      new Date(question.time.seconds)
                                    ).calendar()}
                                  </p>
                                </Card>
                              </div>
                            );
                          }
                        })
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "60%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <CustomInput
                        labelText="Enter message"
                        id="message"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: message,
                          onChange: (text) => {
                            inputChangeM(text);
                          },
                          type: "text",
                          multiline: true,
                        }}
                      />{" "}
                    </div>
                    <Button
                      disabled={convo ? false : true}
                      onClick={() => {
                        sendMessage(meetId);
                      }}
                      style={{ backgroundColor: "#52EA73", marginLeft: 30 }}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>{" "}
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: "url(" + background + ")",
        // backgroundSize: "cover",
        // backgroundPosition: "top center",
        // height: 900,
        // margin: 0,
        // padding: 0,
        // height: "100%"
        // backgroundColor: "red",
        // justifyContent: "center",
        // alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          animationDuration: 2,
          animationName: "pulse",
          animationIterationCount: "infinite",
          animationDirection: "alternate",
        }}
        className="pulsate"
      >
        <h2 style={{ color: "#52EA73", marginRight: 10 }}>Loading ...</h2>
        <img src={logo} alt={""} height={40} />
      </div>
    </div>
  );
}
