import firebase from "../../config/fbConfig";

export const signin = (credentials, account) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        if (account === "admin") {
          console.log("Admin login");
          dispatch({ type: "LOGIN_SUCCESS_ADMIN" });
        } else {
          console.log("Student login");
          dispatch({ type: "LOGIN_SUCCESS" });
        }
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const fecthProfiles = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();

    firestore.collection("users").onSnapshot((snapshot) => {
      const profiles = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch({ type: "LOAD_USERS", allProfiles: profiles });
    });
  };
};

export const forgotPassword = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        alert(
          "Please go to your email and follow the link to change your password."
        );
      })
      .catch((error) => {
        // Some error occurred.
        console.log(error);
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

export const beginSignUp = (user) => {
  return (dispatch) => {
    dispatch({ type: "START_SIGNUP", userInfo: user });
  };
};

export const addUserCourse = (courses, amount) => {
  return (dispatch) => {
    dispatch({ type: "SELECT_COURSES", chosenCourses: courses, total: amount })
      .then(() => {
        console.log("WORKED BRO");
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};

export const signup = (newUser, amountPaid, courses, ref) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // const firebase = firebase
    const firestore = firebase.firestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        return firestore.collection("users").doc(resp.user.uid).set({
          firstName: newUser.firstName,
          lastname: newUser.lastName,
          email: newUser.email,
          status: "",
          courses: courses,
          amountPayed: amountPaid,
          paymentReference: ref,
        });
      })
      .then(() => {
        alert("You are officially signed up!");
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};

export const signupAdmin = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // const firebase = firebase
    const firestore = firebase.firestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        return firestore.collection("users").doc(resp.user.uid).set({
          firstName: newUser.firstName,
          lastname: newUser.lastName,
          email: newUser.email,
          location: "",
          status: "admin",
          applicationTime: "",
          position: "admin",
          ipAdress: "",
          password: "",
        });
      })
      .then(() => {
        firestore.collection("admin").add({
          email: newUser.email,
        });
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
        alert("A new user has been signed up and added as an admin.");
        window.location.reload();
      })
      .catch((err) => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};
