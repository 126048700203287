import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../../components/Header/Header.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import background from "../../assets/images/c4f-background.jpg";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../store/actions/authActions";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";

import HeaderLinksAuth from "../../components/Header/HeaderLinksAuth.js";
import { Redirect } from "react-router-dom";
import { loadCourses } from "../../store/actions/classActions.js";
import { usePaystackPayment } from "react-paystack";
import FooterAuth from "../../components/Footer/FooterAuth.js";
import FooterAuthMB from "../../components/Footer/FooterAuthMB.js";

const useStyles = makeStyles(styles);

const PaymentPage = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  // const [checked, setChecked] = useState(false);
  // const [chosen, setChosen] = useState([]);
  // const [total, setTotal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  const authState = useSelector((state) => state);
  const userAuth = authState.auth;
  const userCourses = userAuth.userCourses;

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const config = {
    reference: new Date().getTime(),
    email: useSelector((state) => state.auth.user.email),
    amount: useSelector((state) => state.auth.userAmount) * 100,
    publicKey: "pk_live_7c36beebac8254cfa1e63a7346640eadb96e55ca",
    currency: "ZAR",
  };

  useEffect(() => {
    dispatch(loadCourses());
  }, [dispatch]);

  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.

    dispatch(
      authActions.signup(
        userAuth.user,
        userAuth.userAmount,
        userCourses,
        reference
      )
    );
  };

  const proceed = async (reference) => {
    let action;

    action = authActions.signup(
      userAuth.user,
      userAuth.userAmount,
      userCourses,
      reference
    );
    setError(null);
    setIsLoading(true);
    try {
      await dispatch(action);
      // <Redirect to="/select-courses" />
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.

    alert("Payment failed.");
  };

  const PaystackHook = () => {
    const initializePayment = usePaystackPayment(config);
    return (
      <div>
        <Button
          simple
          style={{ color: "black", backgroundColor: "#52EA73" }}
          size="lg"
          onClick={() => {
            initializePayment(onSuccess, onClose);
          }}
        >
          Make Payment
        </Button>
      </div>
    );
  };

  if (!authState.auth.user.email) return <Redirect to="/signup" />;
  if (authState.firebase.auth.uid) return <Redirect to="/home" />;
  if (window.innerWidth >= 640)
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="CODING4FINANCE"
          rightLinks={<HeaderLinksAuth />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[cardAnimaton]}>
                  <form className={classes.form}>
                    <CardHeader
                      style={{ backgroundColor: "#737373" }}
                      className={classes.cardHeader}
                    >
                      <h4 style={{ color: "black" }}>Payment</h4>
                    </CardHeader>

                    <CardBody>
                      {" "}
                      <h3>Summary</h3>
                      <div
                        style={{
                          height: 400,
                          overflowY: "scroll",
                          borderRadius: 10,
                        }}
                        className="example"
                      >
                        <h4 style={{ color: "#737373" }}>
                          First Name: {userAuth.user.firstName}
                        </h4>
                        <h4 style={{ color: "#737373" }}>
                          Last Name: {userAuth.user.lastName}
                        </h4>
                        <h4 style={{ color: "#737373" }}>
                          Email: {userAuth.user.email}
                        </h4>
                        <h4 style={{ color: "#737373" }}>Courses: </h4>
                        {userCourses &&
                          userCourses.map((crs, key) => {
                            return (
                              <div>
                                <p style={{ color: "#737373" }}>{crs.title}</p>
                                <p style={{ color: "#737373" }}>
                                  R{crs.amount}
                                </p>
                              </div>
                            );
                          })}
                        <h4 style={{ color: "#737373" }}>
                          Total Amount: R{userAuth.userAmount}
                        </h4>
                      </div>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <PaystackHook />
                      {/* <Button
                      onClick={() => {
                        dispatch(
                          authActions.signup(
                            userAuth.user,
                            userAuth.userAmount,
                            userCourses,
                            "test"
                          )
                        );
                      }}
                    >
                      Sign Up test
                    </Button> */}
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <FooterAuth whiteFont />
        </div>
      </div>
    );

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="CODING4FINANCE"
        rightLinks={<HeaderLinksAuth />}
        {...rest}
      />
      <div
        className={classes.container}
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader
                    style={{ backgroundColor: "#737373" }}
                    className={classes.cardHeader}
                  >
                    <h4 style={{ color: "black" }}>Payment</h4>
                  </CardHeader>

                  <CardBody>
                    {" "}
                    <h3>Summary</h3>
                    <div
                      style={{
                        height: 400,
                        overflowY: "scroll",
                        borderRadius: 10,
                      }}
                      className="example"
                    >
                      <h4 style={{ color: "#737373" }}>
                        First Name: {userAuth.user.firstName}
                      </h4>
                      <h4 style={{ color: "#737373" }}>
                        Last Name: {userAuth.user.lastName}
                      </h4>
                      <h4 style={{ color: "#737373" }}>
                        Email: {userAuth.user.email}
                      </h4>
                      <h4 style={{ color: "#737373" }}>Courses: </h4>
                      {userCourses &&
                        userCourses.map((crs, key) => {
                          return (
                            <div>
                              <p style={{ color: "#737373" }}>{crs.title}</p>
                              <p style={{ color: "#737373" }}>R{crs.amount}</p>
                            </div>
                          );
                        })}
                      <h4 style={{ color: "#737373" }}>
                        Total Amount: R{userAuth.userAmount}
                      </h4>
                    </div>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <PaystackHook />
                    {/* <Button
                      onClick={() => {
                        dispatch(
                          authActions.signup(
                            userAuth.user,
                            userAuth.userAmount,
                            userCourses,
                            "test"
                          )
                        );
                      }}
                    >
                      Sign Up test
                    </Button> */}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <FooterAuthMB whiteFont />
      </div>
    </div>
  );
};

export default PaymentPage;
