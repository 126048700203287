import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import AdminHomePage from "./screens/Admin/AdminHomePage";
import HomePage from "./screens/Student/HomePage";
import LoginPage from "./screens/LoginPage/LoginPage";
import LoginPageAdmin from "./screens/LoginPage/LoginPageAdmin";
import SignUpPage from "./screens/SignUpPage/SignUpPage";
import LessonPage from "./screens/Student/LessonPage";
import CoursePage from "./screens/Student/CoursePage";
import AddCourse from "./screens/Admin/AddCourse";
import AddAdmin from "./screens/Admin/AddAdmin";
import AddLesson from "./screens/Admin/AddLesson";
import LessonPreview from "./screens/Admin/LessonPreview";
import QandAPage from "./screens/Admin/QandAPage";
import SelectCourse from "./screens/SignUpPage/SelectCourse";
import PaymentPage from "./screens/SignUpPage/PaymentPage";
import MeetInstructor from "./screens/Student/MeetInstructor";
import MeetupsPage from "./screens/Admin/MeetupsPage";
import ForgotPassword from "./screens/LoginPage/ForgotPassword";
import { useSelector } from "react-redux";
import background from "./assets/images/c4f-background.jpg";
import logo from "./assets/images/c4f-logo.png";

function App() {
  const authState = useSelector((state) => state.firebase.auth);
  let profileState = useSelector((state) => state.firebase.profile);
  console.log("authentication state:", authState);

  console.log("uid:", !!authState.uid);

  console.log("Loaded:", !!authState.isLoaded);

  if (!!authState.isLoaded) {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route path="/signinadmin" component={LoginPageAdmin} />
            <Route path="/signup" component={SignUpPage} />
            <Route path="/home" component={HomePage} />
            <Route path="/lesson" component={LessonPage} />
            <Route path="/lessons" component={CoursePage} />
            <Route path="/admin-home" component={AdminHomePage} />
            <Route path="/add-admin" component={AddAdmin} />
            <Route path="/add-course" component={AddCourse} />
            <Route path="/add-lesson" component={AddLesson} />
            <Route path="/lesson-preview" component={LessonPreview} />
            <Route path="/select-courses" component={SelectCourse} />
            <Route path="/signup-payment" component={PaymentPage} />
            <Route path="/QandA" component={QandAPage} />
            <Route path="/meet-instructor" component={MeetInstructor} />
            <Route path="/meet-ups" component={MeetupsPage} />
            <Route path="/forgot-password" component={ForgotPassword} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
  return (
    <div
      style={{
        backgroundImage: "url(" + background + ")",
        // backgroundSize: "cover",
        // backgroundPosition: "top center",
        // height: 900,
        // margin: 0,
        // padding: 0,
        // height: "100%"
        // backgroundColor: "red",
        // justifyContent: "center",
        // alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          animationDuration: 2,
          animationName: "pulse",
          animationIterationCount: "infinite",
          animationDirection: "alternate",
        }}
        className="pulsate"
      >
        <h2 style={{ color: "#52EA73", marginRight: 10 }}>Loading ...</h2>
        <img src={logo} alt={""} height={40} />
      </div>
    </div>
  );
}

export default App;
