import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Footer from "../../components/Footer/Footer.js";

import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/c4f-background.jpg";
import Card from "../../components/Card/Card.js";
import HeaderLinksAdmin from "../../components/Header/HeaderLinksAdmin.js";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderAdmin from "../../components/Header/HeaderAdmin.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import { addAnswer, loadQandA } from "../../store/actions/classActions.js";
import moment from "moment";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import { Button } from "@material-ui/core";
import logo from "../../assets/images/c4f-logo.png";

const useStyles = makeStyles(styles);

export default function QandAPage(props) {
  const [message, setMessage] = useState();
  const [q, setQ] = useState();
  const [answer, setAnswer] = useState({ message, q });
  const [selectedQ, setSelectedQ] = useState();

  const classes = useStyles();
  const { ...rest } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadQandA());
  }, [dispatch]);

  const inputChangeM = (value) => {
    setMessage(value.target.value);
  };

  const allQ = useSelector((state) => state.class.allQuestions);
  const unAnsQ = allQ.filter((q) => q.answer == "");

  const ansQ = allQ.filter((q) => q.answer != "");

  const authState = useSelector((state) => state);

  if (!authState.firebase.auth.uid) return <Redirect to="/signinadmin" />;

  if (window.innerWidth >= 640 && authState.auth.adminLoggedIn) {
    return (
      <div>
        <div
          style={{
            backgroundImage: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            height: 750,
          }}
        >
          <HeaderAdmin
            color="white"
            brand="CODING4FINANCE"
            rightLinks={<HeaderLinksAdmin />}
            changeColorOnScroll={{
              height: 200,
              color: "white",
            }}
            {...rest}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <GridContainer style={{ width: "100%" }}>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <h2 style={{ color: "white" }}>Unanswered Questions</h2>
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: 550,
                    overflowY: "scroll",
                  }}
                  className="example"
                >
                  {unAnsQ &&
                    unAnsQ
                      .sort(
                        (a, b) => b.datePosted.seconds - a.datePosted.seconds
                      )
                      .map((question) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                            }}
                          >
                            <Card
                              style={{
                                backgroundColor: "#E3F9EB",
                                margin: 9,
                                textAlign: "left",
                                padding: 7,
                              }}
                              onClick={() => {
                                selectedQ === question.id
                                  ? setSelectedQ("")
                                  : setSelectedQ(question.id);
                              }}
                            >
                              <p>{question.question}</p>
                              <p
                                style={{
                                  color: "grey",
                                  fontSize: 11,
                                  margin: 1,
                                }}
                              >
                                {question.course}: {question.lesson}
                              </p>
                              <p
                                style={{
                                  color: "grey",
                                  fontSize: 11,
                                  margin: 1,
                                }}
                              >
                                Asked by: {question.postedBy}
                              </p>
                              <p
                                style={{
                                  fontSize: 9,
                                  color: "grey",
                                  margin: 1,
                                }}
                              >
                                {moment(
                                  new Date(question.datePosted.seconds * 1000)
                                ).calendar()}
                              </p>
                            </Card>
                            {selectedQ === question.id ? (
                              <Card
                                style={{
                                  backgroundColor: "#C3FFC1",
                                  margin: 9,
                                  textAlign: "right",
                                  padding: 7,
                                }}
                              >
                                <CustomInput
                                  labelText="Answer Question"
                                  id="answer"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: message,
                                    onChange: (text) => {
                                      inputChangeM(text);
                                    },
                                    type: "text",
                                    multiline: true,
                                  }}
                                />
                                <Button
                                  onClick={() => {
                                    dispatch(addAnswer(question.id, message));
                                    setMessage("");
                                  }}
                                >
                                  Send
                                </Button>
                              </Card>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        );
                      })}
                </div>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <h2 style={{ color: "white" }}>Answered Questions</h2>
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: 550,
                    overflowY: "scroll",
                  }}
                  className="example"
                >
                  {ansQ &&
                    ansQ
                      .sort(
                        (a, b) => b.datePosted.seconds - a.datePosted.seconds
                      )
                      .map((question) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                            }}
                          >
                            <Card
                              style={{
                                backgroundColor: "#E3F9EB",
                                margin: 9,
                                textAlign: "left",
                                padding: 7,
                              }}
                            >
                              <p>{question.question}</p>
                              <p
                                style={{
                                  color: "grey",
                                  fontSize: 11,
                                  margin: 1,
                                }}
                              >
                                {question.course}: {question.lesson}
                              </p>
                              <p
                                style={{
                                  color: "grey",
                                  fontSize: 11,
                                  margin: 1,
                                }}
                              >
                                Asked by: {question.postedBy}
                              </p>
                              <p
                                style={{
                                  fontSize: 9,
                                  color: "grey",
                                  margin: 1,
                                }}
                              >
                                {moment(
                                  new Date(question.datePosted.seconds * 1000)
                                ).calendar()}
                              </p>
                            </Card>
                            <Card
                              style={{
                                backgroundColor: "#C3FFC1",
                                margin: 9,
                                textAlign: "right",
                                padding: 7,
                              }}
                            >
                              <p>{question.answer}</p>
                            </Card>
                          </div>
                        );
                      })}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>{" "}
        <Footer />
      </div>
    );
  } else if (window.innerWidth < 640 && authState.auth.adminLoggedIn) {
    return (
      <div>
        <div
          style={{
            backgroundImage: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            height: "100%",
          }}
        >
          <HeaderAdmin
            color="white"
            brand="CODING4FINANCE"
            rightLinks={<HeaderLinksAdmin />}
            changeColorOnScroll={{
              height: 200,
              color: "white",
            }}
            {...rest}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <GridContainer style={{ width: "100%" }}>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <h2 style={{ color: "white" }}>Unanswered Questions</h2>
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: 550,
                    overflowY: "scroll",
                  }}
                  className="example"
                >
                  {unAnsQ &&
                    unAnsQ
                      .sort(
                        (a, b) => b.datePosted.seconds - a.datePosted.seconds
                      )
                      .map((question) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                            }}
                          >
                            <Card
                              style={{
                                backgroundColor: "#E3F9EB",
                                margin: 9,
                                textAlign: "left",
                                padding: 7,
                              }}
                              onClick={() => {
                                selectedQ === question.id
                                  ? setSelectedQ("")
                                  : setSelectedQ(question.id);
                              }}
                            >
                              <p>{question.question}</p>
                              <p
                                style={{
                                  color: "grey",
                                  fontSize: 11,
                                  margin: 1,
                                }}
                              >
                                {question.course}: {question.lesson}
                              </p>
                              <p
                                style={{
                                  color: "grey",
                                  fontSize: 11,
                                  margin: 1,
                                }}
                              >
                                Asked by: {question.postedBy}
                              </p>
                              <p
                                style={{
                                  fontSize: 9,
                                  color: "grey",
                                  margin: 1,
                                }}
                              >
                                {moment(
                                  new Date(question.datePosted.seconds * 1000)
                                ).calendar()}
                              </p>
                            </Card>
                            {selectedQ === question.id ? (
                              <Card
                                style={{
                                  backgroundColor: "#C3FFC1",
                                  margin: 9,
                                  textAlign: "right",
                                  padding: 7,
                                }}
                              >
                                <CustomInput
                                  labelText="Answer Question"
                                  id="answer"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: message,
                                    onChange: (text) => {
                                      inputChangeM(text);
                                    },
                                    type: "text",
                                    multiline: true,
                                  }}
                                />
                                <Button
                                  onClick={() => {
                                    dispatch(addAnswer(question.id, message));
                                    setMessage("");
                                  }}
                                >
                                  Send
                                </Button>
                              </Card>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        );
                      })}
                </div>
              </GridItem>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <h2 style={{ color: "white" }}>Answered Questions</h2>
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    height: 550,
                    overflowY: "scroll",
                  }}
                  className="example"
                >
                  {ansQ &&
                    ansQ
                      .sort(
                        (a, b) => b.datePosted.seconds - a.datePosted.seconds
                      )
                      .format()

                      .map((question) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                            }}
                          >
                            <Card
                              style={{
                                backgroundColor: "#E3F9EB",
                                margin: 9,
                                textAlign: "left",
                                padding: 7,
                              }}
                            >
                              <p>{question.question}</p>
                              <p
                                style={{
                                  color: "grey",
                                  fontSize: 11,
                                  margin: 1,
                                }}
                              >
                                {question.course}: {question.lesson}
                              </p>
                              <p
                                style={{
                                  color: "grey",
                                  fontSize: 11,
                                  margin: 1,
                                }}
                              >
                                Asked by: {question.postedBy}
                              </p>
                              <p
                                style={{
                                  fontSize: 9,
                                  color: "grey",
                                  margin: 1,
                                }}
                              >
                                {moment(
                                  new Date(question.datePosted.seconds * 1000)
                                ).calendar()}
                              </p>
                            </Card>
                            <Card
                              style={{
                                backgroundColor: "#C3FFC1",
                                margin: 9,
                                textAlign: "right",
                                padding: 7,
                              }}
                            >
                              <p>{question.answer}</p>
                            </Card>
                          </div>
                        );
                      })}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>{" "}
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: "url(" + background + ")",
        // backgroundSize: "cover",
        // backgroundPosition: "top center",
        // height: 900,
        // margin: 0,
        // padding: 0,
        // height: "100%"
        // backgroundColor: "red",
        // justifyContent: "center",
        // alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          animationDuration: 2,
          animationName: "pulse",
          animationIterationCount: "infinite",
          animationDirection: "alternate",
        }}
        className="pulsate"
      >
        <h2 style={{ color: "#52EA73", marginRight: 10 }}>Loading ...</h2>
        <img src={logo} alt={""} height={40} />
      </div>
    </div>
  );
}
