const initialState = {
  admins: [],
  students: [],
  sessions: [],
  courses: [],
  courseTitle: "",
  lessons: [],
  currentLesson: [],
  currentQuestions: [],
  allQuestions: [],
  userCourses: [],
  meets: [],
};

const classReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_ADMINS":
      const adminList = action.admins;
      return { ...state, admins: adminList };
    case "LOAD_COURSES":
      const courseList = action.courses;
      return { ...state, courses: courseList };
    case "SET_COURSE":
      return { ...state, courseTitle: action.courseTitle };
    case "LOAD_LESSONS":
      return {
        ...state,
        lessons: action.courseLessons.sort(
          (a, b) => a.lessonNumber - b.lessonNumber
        ),
      };
    case "SELECT_LESSON":
      const curLsn = state.lessons.find(
        (gr) => gr.lessonNumber === action.currentLesson
      );

      return { ...state, currentLesson: curLsn };
    case "LOAD_QUESTIONS":
      return { ...state, allQuestions: action.allQuestions };
    case "LOAD_MEETS":
      return { ...state, meets: action.meets };

    default:
      return state;
  }
};

export default classReducer;
