import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import People from "@material-ui/icons/People";
// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import background from "../../assets/images/c4f-background.jpg";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as authActions from "../../store/actions/authActions";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";

import HeaderLinksAuth from "../../components/Header/HeaderLinksAuth.js";
import { NavLink, Redirect } from "react-router-dom";
import FooterAuth from "../../components/Footer/FooterAuth.js";
import FooterAuthMB from "../../components/Footer/FooterAuthMB.js";
// import { NavLink } from "react-router-dom";

const useStyles = makeStyles(styles);

const LoginPage = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  // const [firstName, setFirstName] = useState();
  // const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const dispatch = useDispatch();

  const authState = useSelector((state) => state);
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const user = { email, password };

  const inputChangeE = (value) => {
    setEmail(value.target.value);
  };

  const inputChangeP = (value) => {
    setPassword(value.target.value);
  };

  const togglePassword = () => {
    passwordType === "text"
      ? setPasswordType("password")
      : setPasswordType("text");
  };

  const authHandler = async () => {
    setError("error");
    let action;

    action = authActions.signin(user, "student");
    // setError(null);
    setIsLoading(true);
    try {
      console.log("starting auth process");
      await dispatch(action);
    } catch (err) {
      setError("error");
      setIsLoading(false);
    }
  };
  console.log("Logged in status:", authState.auth.studentLoggedIn);
  console.log("state:", authState);

  if (authState.auth.studentLoggedIn && authState.firebase.auth.uid) return <Redirect to="/home" />;

  if (window.innerWidth >= 640)
    return (
      <div>
        <Header
          absolute
          color="transparent"
          brand="CODING4FINANCE"
          rightLinks={<HeaderLinksAuth />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[cardAnimaton]}>
                  <form className={classes.form}>
                    <CardHeader
                      style={{
                        backgroundColor: "white",
                        borderWidth: 2,
                        borderColor: "black",
                      }}
                      className={classes.cardHeader}
                    >
                      <h4 style={{ color: "black" }}>Sign into your account</h4>
                    </CardHeader>

                    <CardBody>
                      <CustomInput
                        labelText="Email..."
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (text) => {
                            inputChangeE(text);
                          },
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomInput
                        labelText="Password..."
                        id="Password"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (text) => {
                            inputChangeP(text);
                          },
                          type: passwordType,
                          endAdornment: (
                            <InputAdornment position="end">
                              <div
                                onClick={() => {
                                  togglePassword();
                                }}
                              >
                                <VisibilityIcon />
                              </div>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <div>
                        {error && (
                          <p style={{ color: "red" }}>
                            {authState.auth.authError}
                          </p>
                        )}

                        <Button
                          simple
                          style={{ color: "black", backgroundColor: "#52EA73" }}
                          size="lg"
                          onClick={authHandler}
                        >
                          Login
                        </Button>

                        <NavLink
                          style={{
                            textDecorationLine: "none",
                            color: "#737373",
                          }}
                          to="/forgot-password"
                        >
                          <h4>Forgot Password</h4>
                        </NavLink>
                      </div>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <FooterAuth whiteFont />
        </div>
      </div>
    );
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="CODING4FINANCE"
        rightLinks={<HeaderLinksAuth />}
        {...rest}
      />
      <div
        className={classes.container}
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <GridContainer justify="center">
          <GridItem
            xs={12}
            sm={12}
            md={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card className={classes[cardAnimaton]} style={{ width: "90%" }}>
              <form className={classes.form}>
                <CardHeader
                  style={{
                    backgroundColor: "white",
                    borderWidth: 2,
                    borderColor: "black",
                  }}
                  className={classes.cardHeader}
                >
                  <h4 style={{ color: "black" }}>Sign into your account</h4>
                </CardHeader>

                <CardBody>
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (text) => {
                        inputChangeE(text);
                      },
                      type: "email",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Password..."
                    id="Password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (text) => {
                        inputChangeP(text);
                      },
                      type: passwordType,
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            onClick={() => {
                              togglePassword();
                            }}
                          >
                            <VisibilityIcon />
                          </div>
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <div>
                    {error && (
                      <p style={{ color: "red" }}>{authState.auth.authError}</p>
                    )}

                    <Button
                      simple
                      style={{ color: "black", backgroundColor: "#52EA73" }}
                      size="lg"
                      onClick={authHandler}
                    >
                      Login
                    </Button>

                    <NavLink
                      style={{
                        textDecorationLine: "none",
                        color: "#737373",
                      }}
                      to="/forgot-password"
                    >
                      <h4>Forgot Password</h4>
                    </NavLink>
                  </div>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>

        <FooterAuthMB whiteFont />
      </div>
    </div>
  );
};

export default LoginPage;
