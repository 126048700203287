import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import background from "../../assets/images/c4f-background.jpg";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as authActions from "../../store/actions/authActions";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "../../assets/jss/material-kit-react/views/loginPage.js";

import HeaderLinksAuth from "../../components/Header/HeaderLinksAuth.js";
import { NavLink, Redirect } from "react-router-dom";
import { loadCourses } from "../../store/actions/classActions.js";
import FooterAuth from "../../components/Footer/FooterAuth.js";
import FooterAuthMB from "../../components/Footer/FooterAuthMB.js";

const useStyles = makeStyles(styles);

const SelectCourse = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [checked, setChecked] = useState(false);
  const [chosen, setChosen] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    dispatch(loadCourses());
  }, [dispatch]);



  const proceed = async () => {
    let action;
 

    action = authActions.addUserCourse(chosen, total);
    setError(null);
    setIsLoading(true);
    try {
      await dispatch(action);
      // <Redirect to="/select-courses" />
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };


  const courseList = useSelector((state) => state.class.courses);



  const authState = useSelector((state) => state);

  if (!authState.auth.user.email) return <Redirect to="/signup" />;
  if (window.innerWidth >= 640)
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="CODING4FINANCE"
        rightLinks={<HeaderLinksAuth />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader
                    style={{ backgroundColor: "#737373" }}
                    className={classes.cardHeader}
                  >
                    <h4 style={{ color: "black" }}>Choose your courses</h4>
                    <h5>Current Total: R{total}</h5>
                  </CardHeader>

                  <CardBody>
                    <div
                      style={{
                        height: 400,
                        overflowY: "scroll",
                        borderRadius: 10,
                      }}
                      className="example"
                    >
                      {courseList &&
                        courseList.map((crs, key) => {
                          return (
                            <Card
                              onClick={() => {
                      
                                const alreadyChosen = chosen.findIndex(
                                  (gr) => gr == crs
                                );
                     
                                if (alreadyChosen == -1) {
                         
                                  setChosen(chosen.concat(crs));
                                  setTotal(total + Number(crs.amount));
                                } else {
                         
                                  setChosen(chosen.filter((gr) => gr != crs));
                                  setTotal(total - Number(crs.amount));
                                }
                                // calcTotal(chosen);
                              }}
                            >
                              <div>
                                <h4
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    marginTop: 4,
                                  }}
                                >
                                  {crs.title}
                                </h4>
                                <h6
                                  style={{
                                    margin: 2,
                                    padding: 0,
                                    color: "#737373",
                                  }}
                                >
                                  R{crs.amount}
                                </h6>
                                <p style={{ fontSize: 10 }}>
                                  {crs.description}
                                </p>
                              </div>{" "}
                              <div>
                                <Checkbox
                                  checked={
                                    chosen.findIndex((gr) => gr === crs) !== -1
                                  }
                                  onChange={() => {
                           
                                    //
                                  }}
                                  inputProps={{
                                    "aria-label": `${crs.title}`,
                                  }}
                                />
                              </div>
                            </Card>
                          );
                        })}
                    </div>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <NavLink
                      style={{ textDecorationLine: "none" }}
                      to="/signup-payment"
                      onClick={() => {
                     
                        proceed();
                      }}
                    >
                      <Button
                        simple
                        style={{ color: "black", backgroundColor: "#52EA73" }}
                        size="lg"
                      >
                        Proceed to Payment
                      </Button>
                    </NavLink>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <FooterAuth whiteFont />
      </div>
    </div>
  );

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="CODING4FINANCE"
        rightLinks={<HeaderLinksAuth />}
        {...rest}
      />
      <div
        className={classes.container}
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader
                    style={{ backgroundColor: "#737373" }}
                    className={classes.cardHeader}
                  >
                    <h4 style={{ color: "black" }}>Choose your courses</h4>
                    <h5>Current Total: R{total}</h5>
                  </CardHeader>

                  <CardBody>
                    <div
                      style={{
                        height: 400,
                        overflowY: "scroll",
                        borderRadius: 10,
                      }}
                      className="example"
                    >
                      {courseList &&
                        courseList.map((crs, key) => {
                          return (
                            <Card
                              onClick={() => {
                      
                                const alreadyChosen = chosen.findIndex(
                                  (gr) => gr == crs
                                );
                     
                                if (alreadyChosen == -1) {
                         
                                  setChosen(chosen.concat(crs));
                                  setTotal(total + Number(crs.amount));
                                } else {
                         
                                  setChosen(chosen.filter((gr) => gr != crs));
                                  setTotal(total - Number(crs.amount));
                                }
                                // calcTotal(chosen);
                              }}
                            >
                              <div>
                                <h4
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    marginTop: 4,
                                  }}
                                >
                                  {crs.title}
                                </h4>
                                <h6
                                  style={{
                                    margin: 2,
                                    padding: 0,
                                    color: "#737373",
                                  }}
                                >
                                  R{crs.amount}
                                </h6>
                                <p style={{ fontSize: 10 }}>
                                  {crs.description}
                                </p>
                              </div>{" "}
                              <div>
                                <Checkbox
                                  checked={
                                    chosen.findIndex((gr) => gr === crs) !== -1
                                  }
                                  onChange={() => {
                           
                                    //
                                  }}
                                  inputProps={{
                                    "aria-label": `${crs.title}`,
                                  }}
                                />
                              </div>
                            </Card>
                          );
                        })}
                    </div>
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <NavLink
                      style={{ textDecorationLine: "none" }}
                      to="/signup-payment"
                      onClick={() => {
                     
                        proceed();
                      }}
                    >
                      <Button
                        simple
                        style={{ color: "black", backgroundColor: "#52EA73" }}
                        size="lg"
                      >
                        Proceed to Payment
                      </Button>
                    </NavLink>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <FooterAuthMB whiteFont />
      </div>
    </div>
  );
};

export default SelectCourse;
