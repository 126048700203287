import React, { useEffect, useState } from "react";

import Footer from "../../components/Footer/Footer.js";

import background from "../../assets/images/c4f-background.jpg";
import Card from "../../components/Card/Card.js";
import HeaderLinksAdmin from "../../components/Header/HeaderLinksAdmin.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import { Button } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import {
  createCourse,
  loadCourses,
  setCourse,
} from "../../store/actions/classActions.js";
import HeaderAdmin from "../../components/Header/HeaderAdmin.js";
import logo from "../../assets/images/c4f-logo.png";

export default function AddCourse(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");

  const { ...rest } = props;

  const dispatch = useDispatch();

  const inputChangeT = (value) => {
    setTitle(value.target.value);
  };
  const inputChangeD = (value) => {
    setDescription(value.target.value);
  };
  const inputChangeA = (value) => {
    setAmount(value.target.value);
  };

  const formcheck = () => {
    if (title === "" || amount === "") {
      alert("Please fill in title and amount.");
      return false;
    }
    createNewCourse();
    return true;
  };

  const createNewCourse = () => {
    dispatch(createCourse(title, description, currentAdmin, amount));
  };

  // useCallback(() => {
  //   console.log("Callback after creation");
  // }, [createNewCourse]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(loadCourses());
  }, [dispatch]);
  const authState = useSelector((state) => state);
  const courseList = useSelector((state) => state.class.courses);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const currentAdmin = authState.firebase.profile.email;

  if (!authState.firebase.auth.uid) {
    return <Redirect to="/signinadmin" />;
  }
  if (authState.auth.adminLoggedIn) {
    return (
      <div>
        <div
          style={{
            backgroundImage: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            height: "100%",
          }}
        >
          <HeaderAdmin
            color="white"
            brand="CODING4FINANCE"
            rightLinks={<HeaderLinksAdmin />}
            changeColorOnScroll={{
              height: 200,
              color: "white",
            }}
            {...rest}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <GridContainer style={{ width: "100%" }}>
              <GridItem
                style={{ justifyContent: "left" }}
                xs={12}
                sm={12}
                md={6}
              >
                <h2 style={{ color: "white" }}>New Course</h2>
                <Card style={{ padding: 20, width: "90%" }}>
                  <div>
                    <div>
                      <CustomInput
                        labelText="Course Title"
                        id="title"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (text) => {
                            inputChangeT(text);
                          },
                          type: "title",
                        }}
                      />
                      <CustomInput
                        labelText="Description"
                        id="description"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (text) => {
                            inputChangeD(text);
                          },
                          type: "description",
                          multiline: true,
                        }}
                      />
                      <CustomInput
                        labelText="Amount"
                        id="amount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (text) => {
                            inputChangeA(text);
                          },
                          type: "number",
                        }}
                      />
                    </div>

                    <div style={{ marginTop: 20 }}>
                      <Button
                        style={{ backgroundColor: "#52EA73", color: "black" }}
                        onClick={() => {
                          const submit = formcheck();
                          if (submit === true) {
                            setTitle("");
                            setDescription("");
                            setAmount("");
                          }
                        }}
                      >
                        Create Course
                      </Button>
                    </div>
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <h2 style={{ color: "white" }}>Courses</h2>
                <div
                  style={{
                    height: 550,
                    overflowY: "scroll",
                    borderRadius: 10,
                  }}
                  className="example"
                >
                  {courseList &&
                    courseList.map((crs, key) => {
                      return (
                        <NavLink
                          onClick={() => {
                            dispatch(setCourse(crs.title));
                          }}
                          to="/add-lesson"
                          style={{ textDecorationLine: "none", margin: 10 }}
                        >
                          <Card>
                            <h4>{crs.title}</h4>
                            <p>{crs.description}</p>
                          </Card>
                        </NavLink>
                      );
                    })}
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: "url(" + background + ")",
        // backgroundSize: "cover",
        // backgroundPosition: "top center",
        // height: 900,
        // margin: 0,
        // padding: 0,
        // height: "100%"
        // backgroundColor: "red",
        // justifyContent: "center",
        // alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          animationDuration: 2,
          animationName: "pulse",
          animationIterationCount: "infinite",
          animationDirection: "alternate",
        }}
        className="pulsate"
      >
        <h2 style={{ color: "#52EA73", marginRight: 10 }}>Loading ...</h2>
        <img src={logo} alt={""} height={40} />
      </div>
    </div>
  );
}
