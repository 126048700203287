/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.js";
import { useDispatch, useSelector } from "react-redux";
import Button from "../CustomButtons/Button.js";
import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";
import * as authActions from "../../store/actions/authActions";


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const dispacth = useDispatch();
  return (
    <List className={classes.list}>
      <ListItem
        className={classes.listItem}
        style={{
          marginRight: 10,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NavLink
          style={{
            color: "#52EA73",
            textDecorationLine: "none",
            marginRight: 10,
          }}
          to={"/home"}
        >
          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
            style={{
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            Home
          </Button>
        </NavLink>
      </ListItem>
      <ListItem
        className={classes.listItem}
        style={{
          marginRight: 10,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NavLink
          style={{
            color: "#737373",
            textDecorationLine: "none",
            marginRight: 10,
          }}
          to={"/meet-instructor"}
        >
          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
            style={{
              fontWeight: "bold",
              fontSize: 14,
            }}
          >
            Meet Instructor
          </Button>
        </NavLink>
      </ListItem>
      <ListItem className={classes.listItem} style={{ marginRight: 10 }}>
        <NavLink
          style={{ color: "#737373", textDecorationLine: "none" }}
          to={"/"}
        >
          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
            style={{
              fontWeight: "bold",
              fontSize: 14,
            }}
            onClick={()=> {
              console.log("logging out user")
              dispacth(authActions.signOut());
            }}
          >
            Logout
          </Button>
        </NavLink>
      </ListItem>

    </List>
  );
}
