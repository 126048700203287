import React, { useEffect } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../components/Card/Card.js";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import FooterMB from "../../components/Footer/FooterMB.js";
import background from "../../assets/images/c4f-background.jpg";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import { loadLessons, selectLesson } from "../../store/actions/classActions.js";

export default function CoursePage(props) {
  const { ...rest } = props;
  const dispatch = useDispatch();

  const currentCourse = useSelector((state) => state.class.courseTitle);
  useEffect(() => {
    dispatch(loadLessons(currentCourse));
  }, [dispatch, currentCourse]);

  const lessonsList = useSelector((state) => state.class.lessons);

  const authState = useSelector((state) => state);
  console.log("Lessons page state:", authState);

  if (!authState.firebase.auth.uid) return <Redirect to="/" />;

  if (window.innerWidth >= 640)
    return (
      <div>
        <div
          style={{
            backgroundImage: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            height: 800,
          }}
        >
          <div style={{ height: "90%" }}>
            <Header
              color="white"
              brand="CODING4FINANCE"
              rightLinks={<HeaderLinks />}
              changeColorOnScroll={{
                height: 200,
                color: "white",
              }}
              {...rest}
            />
            <h2 style={{ color: "white" }}>{currentCourse} Lessons</h2>
            <div
              style={{
                height: 500,
                overflowY: "scroll",
                borderRadius: 10,
              }}
              className="example"
            >
              {lessonsList &&
                lessonsList.map((lsn, key) => {
                  return (
                    <NavLink
                      onClick={() => {
                        dispatch(selectLesson(lsn.lessonNumber));
                      }}
                      to="/lesson"
                      style={{
                        textDecorationLine: "none",
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Card
                        style={{ width: "80%" }}
                        onClick={() => {
                          dispatch(selectLesson(lsn.lessonNumber));
                        }}
                      >
                        <h4>{lsn.title}</h4>
                        <p>This is lesson number-{lsn.lessonNumber}</p>
                      </Card>
                    </NavLink>
                  );
                })}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );

  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          height: 800,
        }}
      >
        <div style={{ height: "90%" }}>
          <Header
            color="white"
            brand="CODING4FINANCE"
            rightLinks={<HeaderLinks />}
            changeColorOnScroll={{
              height: 200,
              color: "white",
            }}
            {...rest}
          />
          <h2 style={{ color: "white" }}>{currentCourse} Lessons</h2>
          <div
            style={{
              height: 500,
              overflowY: "scroll",
              borderRadius: 10,
            }}
            className="example"
          >
            {lessonsList &&
              lessonsList.map((lsn, key) => {
                return (
                  <NavLink
                    onClick={() => {
                      dispatch(selectLesson(lsn.lessonNumber));
                    }}
                    to="/lesson"
                    style={{
                      textDecorationLine: "none",
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Card
                      style={{ width: "80%" }}
                      onClick={() => {
                        dispatch(selectLesson(lsn.lessonNumber));
                      }}
                    >
                      <h4>{lsn.title}</h4>
                      <p>This is lesson number-{lsn.lessonNumber}</p>
                    </Card>
                  </NavLink>
                );
              })}
          </div>
          <FooterMB />
        </div>
      </div>
    </div>
  );
}
