const initState = {
  authError: null,
  user: {},
  userCourses: [],
  userAmount: 0,
  profiles: [],
  studentLoggedIn: false,
  adminLoggedIn: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS_ADMIN":
      return {
        ...state,
        authError: null,
        adminLoggedIn: true,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        authError: "Login failed",
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        authError: null,
        studentLoggedIn: true,
      };
    case "SIGNOUT_SUCCESS":
      return {
        authError: null,
        user: {},
        userCourses: [],
        userAmount: 0,
        profiles: [],
        studentLoggedIn: false,
        adminLoggedIn: false,
      };
    case "SIGNIP_SUCCESS":
      return {
        ...state,
        authError: null,
      };
    case "SIGNUP_ERROR":
      return {
        ...state,
        authError: action.err.message,
      };
    case "START_SIGNUP":
      return { ...state, user: action.userInfo };

    case "LOAD_USERS":
      return { ...state, profiles: action.allProfiles };
    case "SELECT_COURSES":
      return {
        ...state,
        userCourses: action.chosenCourses,
        userAmount: action.total,
      };
    default:
      return state;
  }
};

export default authReducer;
