import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import background from "../../assets/images/c4f-background.jpg";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as authActions from "../../store/actions/authActions";

import styles from "../../assets/jss/material-kit-react/views/loginPage.js";

import HeaderLinksAuth from "../../components/Header/HeaderLinksAuth.js";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import FooterAuth from "../../components/Footer/FooterAuth.js";
import { useEffect } from "react";
import FooterAuthMB from "../../components/Footer/FooterAuthMB.js";

const useStyles = makeStyles(styles);

const SignUpPage = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(authActions.fecthProfiles());
  }, [dispatch]);

  const profiles = useSelector((state) => state.auth.profiles);
  console.log("Profiles:", profiles);

  useEffect(() => {
    if (
      email === "" ||
      password === "" ||
      firstName === "" ||
      lastName === ""
    ) {
      setErrorMessage("Please complete all the fields");
    } else if (profiles.findIndex((prof) => prof.email === email) !== -1) {
      setErrorMessage("This email already has an account");
    } else {
      return;
    }
  }, [email, password, firstName, lastName, profiles]);

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;

  const user = { firstName, lastName, email, password };

  const inputChangeF = (value) => {
    setFirstName(value.target.value);
  };

  const inputChangeL = (value) => {
    setLastName(value.target.value);
  };

  const inputChangeE = (value) => {
    setEmail(value.target.value);
  };

  const inputChangeP = (value) => {
    setPassword(value.target.value);
  };

  const togglePassword = () => {
    passwordType == "text"
      ? setPasswordType("password")
      : setPasswordType("text");
  };

  const authHandler = async () => {
    let action;
    console.log("Auth handler");
    action = authActions.beginSignUp(user);
    setError(null);
    setIsLoading(true);

    try {
      await dispatch(action);
      let path = "/select-courses";
      history.push(path);
      console.log("Path:", path);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
      console.log("error:", err);
    }
  };

  const authState = useSelector((state) => state);
  if (authState.firebase.auth.uid) return <Redirect to="/home" />;
  if (window.innerWidth >= 640)
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="CODING4FINANCE"
        rightLinks={<HeaderLinksAuth />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader
                    style={{ backgroundColor: "#737373" }}
                    className={classes.cardHeader}
                  >
                    <h4 style={{ color: "black" }}>Create an account</h4>
                  </CardHeader>

                  <CardBody>
                    <CustomInput
                      labelText="First Name..."
                      id="first"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (text) => inputChangeF(text),
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />{" "}
                    <CustomInput
                      labelText="Last Name..."
                      id="lastName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (text) => {
                          inputChangeL(text);
                        },
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (text) => {
                          inputChangeE(text);
                        },
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Password..."
                      id="Password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (text) => {
                          inputChangeP(text);
                        },
                        type: passwordType,
                        endAdornment: (
                          <InputAdornment position="end">
                            <div
                              onClick={() => {
                                togglePassword();
                              }}
                            >
                              <VisibilityIcon />
                            </div>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    {profiles.findIndex((prof) => prof.email === email) !==
                      -1 ||
                    email === "" ||
                    password === "" ||
                    firstName === "" ||
                    lastName === "" ? (
                      <div>
                        <Button
                          disabled
                          simple
                          style={{ color: "black", backgroundColor: "#52EA73" }}
                          size="lg"
                        >
                          Sign Up
                        </Button>

                        <p style={{ color: "red" }}>
                       {errorMessage}
                        </p>
                      </div>
                    ) : (
                      <Button
                        simple
                        style={{ color: "black", backgroundColor: "#52EA73" }}
                        size="lg"
                        onClick={() => {
                          authHandler();
                        }}
                      >
                        Sign Up
                      </Button>
                    )}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <FooterAuth whiteFont />
      </div>
    </div>
  );
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="CODING4FINANCE"
        rightLinks={<HeaderLinksAuth />}
        {...rest}
      />
      <div

        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
        className={classes.container}
      >
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}  style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Card className={classes[cardAnimaton]} style={{ width: "90%" }}>
                <form className={classes.form}>
                  <CardHeader
                    style={{ backgroundColor: "#737373" }}
                    className={classes.cardHeader}
                  >
                    <h4 style={{ color: "black" }}>Create an account</h4>
                  </CardHeader>

                  <CardBody>
                    <CustomInput
                      labelText="First Name..."
                      id="first"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (text) => inputChangeF(text),
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />{" "}
                    <CustomInput
                      labelText="Last Name..."
                      id="lastName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (text) => {
                          inputChangeL(text);
                        },
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (text) => {
                          inputChangeE(text);
                        },
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CustomInput
                      labelText="Password..."
                      id="Password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (text) => {
                          inputChangeP(text);
                        },
                        type: passwordType,
                        endAdornment: (
                          <InputAdornment position="end">
                            <div
                              onClick={() => {
                                togglePassword();
                              }}
                            >
                              <VisibilityIcon />
                            </div>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    {profiles.findIndex((prof) => prof.email === email) !==
                      -1 ||
                    email === "" ||
                    password === "" ||
                    firstName === "" ||
                    lastName === "" ? (
                      <div>
                        <Button
                          disabled
                          simple
                          style={{ color: "black", backgroundColor: "#52EA73" }}
                          size="lg"
                        >
                          Sign Up
                        </Button>

                        <p style={{ color: "red" }}>
                       {errorMessage}
                        </p>
                      </div>
                    ) : (
                      <Button
                        simple
                        style={{ color: "black", backgroundColor: "#52EA73" }}
                        size="lg"
                        onClick={() => {
                          authHandler();
                        }}
                      >
                        Sign Up
                      </Button>
                    )}
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <FooterAuthMB whiteFont />
      </div>
    </div>
  );
};

export default SignUpPage;
