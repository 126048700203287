import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";

import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";

import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/c4f-background.jpg";
import Card from "../../components/Card/Card.js";
import { NavLink, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCourses,
  setCourse,
  loadMeets,
} from "../../store/actions/classActions.js";
import FooterMB from "../../components/Footer/FooterMB.js";

const useStyles = makeStyles(styles);

export default function HomePage(props) {
  // const [courseList, setCourseList] = useState([])
  const [setLoading, loading] = useState();
  const [setLoaded, loaded] = useState();
  const classes = useStyles();
  const { ...rest } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCourses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadMeets());
  }, [dispatch]);

  const authState = useSelector((state) => state);
  const courseListAll = useSelector((state) => state.class.courses);
  const courseList = authState.firebase.profile.courses;

  // useEffect(() => {
  //   setLoading(true);
  //   window.setTimeout(setLoaded(true), );
  // }, []);
  console.log("Auth State::", authState);
  if (!authState.firebase.auth.uid) {
    return <Redirect to="/" />;
  }
  if (window.innerWidth >= 640)
    return (
      <div
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          height: window.innerHeight >= 800 ? "100%" : 800,
        }}
      >
        {" "}
        <div style={{ height: "90%" }}>
          <Header
            color="white"
            brand="CODING4FINANCE"
            rightLinks={<HeaderLinks />}
            changeColorOnScroll={{
              height: "70%",
              color: "white",
            }}
            {...rest}
          />{" "}
          <h2 style={{ color: "white" }}>Courses</h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                height: 500,
                overflowY: "scroll",
                borderRadius: 10,
                width: "80%",
              }}
              className="example"
            >
              {courseList &&
                courseList.map((crs, key) => {
                  return (
                    <NavLink
                      onClick={() => {
                        dispatch(setCourse(crs.title));
                      }}
                      to="/lessons"
                      style={{ textDecorationLine: "none", margin: 10 }}
                    >
                      <Card>
                        <h4>{crs.title}</h4>
                        <p>{crs.description}</p>
                      </Card>
                    </NavLink>
                  );
                })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );

  return (
    <div
      style={{
        backgroundImage: "url(" + background + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
        height: window.innerHeight >= 800 ? "100%" : 800,
      }}
    >
      {" "}
      <div style={{ height: "90%" }}>
        <Header
          color="white"
          brand="CODING4FINANCE"
          rightLinks={<HeaderLinks />}
          changeColorOnScroll={{
            height: "70%",
            color: "white",
          }}
          {...rest}
        />{" "}
        <h2 style={{ color: "white" }}>Courses</h2>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              height: 500,
              overflowY: "scroll",
              borderRadius: 10,
              width: "80%",
            }}
            className="example"
          >
            {courseList &&
              courseList.map((crs, key) => {
                return (
                  <NavLink
                    onClick={() => {
                      dispatch(setCourse(crs.title));
                    }}
                    to="/lessons"
                    style={{ textDecorationLine: "none", margin: 10 }}
                  >
                    <Card>
                      <h4>{crs.title}</h4>
                      <p>{crs.description}</p>
                    </Card>
                  </NavLink>
                );
              })}
          </div>
        </div>{" "}
        <FooterMB />
      </div>
    </div>
  );
}
