import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyC7JcYn4XvO6081lsmReDpeE2LlEt7cmEk",
  authDomain: "coding4finance-bae1d.firebaseapp.com",
  projectId: "coding4finance-bae1d",
  storageBucket: "coding4finance-bae1d.appspot.com",
  messagingSenderId: "305868637752",
  appId: "1:305868637752:web:36a0dd484106a97cfd88cd",
  measurementId: "G-GP26DQHT1M",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//   firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;
