import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";

import HeaderLinks from "../../components/Header/HeaderLinks.js";

import styles from "../../assets/jss/material-kit-react/views/profilePage.js";
import background from "../../assets/images/c4f-background.jpg";
import Card from "../../components/Card/Card.js";
import ReactPlayer from "react-player";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Button } from "@material-ui/core";
import HeaderLinksAdmin from "../../components/Header/HeaderLinksAdmin.js";
import HeaderAdmin from "../../components/Header/HeaderAdmin.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import logo from "../../assets/images/c4f-logo.png";
import {
  addQuestion,
  loadQandA,
  selectLesson,
} from "../../store/actions/classActions.js";
import moment from "moment";

const useStyles = makeStyles(styles);

const LessonPreview = (props) => {
  const classes = useStyles();
  const { ...rest } = props;
  const [tabValue, setTabValue] = useState(0);
  const [message, setMessage] = useState();

  const dispatch = useDispatch();

  const authState = useSelector((state) => state);
  const fName = authState.firebase.profile.firstName;
  const lName = authState.firebase.profile.lastname;

  useEffect(() => {
    dispatch(loadQandA());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const currentLess = useSelector((state) => state.class.currentLesson);
  const allQ = useSelector((state) => state.class.allQuestions);
  const courseQ = allQ.filter((q) => q.course === currentLess.course);
  const lessonQ = courseQ.filter((q) => q.lesson === currentLess.title);

  // console.log("current lesson:", currentLess);
  const user = `${fName} ${lName}`;

  const inputChangeM = (value) => {
    setMessage(value.target.value);
  };
  const lessonsList = useSelector((state) => state.class.lessons);

  const sendQuestion = () => {
    dispatch(addQuestion(message, currentLess.course, currentLess.title, user));
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <p>{children}</p>}
      </div>
    );
  };

  if (!authState.firebase.auth.uid) {
    return <Redirect to="/signinadmin" />;
  }

  if (authState.auth.adminLoggedIn) {
    return (
      <div>
        {" "}
        <div
          style={{
            backgroundImage: "url(" + background + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          {" "}
          <HeaderAdmin
            color="white"
            brand="CODING4FINANCE"
            rightLinks={<HeaderLinksAdmin />}
            changeColorOnScroll={{
              height: 200,
              color: "white",
            }}
            {...rest}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                backgroundColor: "whitesmoke",
                borderRadius: 4,
              }}
            >
              <div style={{ backgroundColor: "green", width: "100%" }}>
                <h1 style={{ color: "white" }}>
                  {currentLess.course}: {currentLess.title} (Admin Preview)
                </h1>
              </div>
              <h5>Lesson {currentLess.lessonNumber}</h5>
              {window.innerWidth >= 640 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "80%",
                  }}
                >
                  <div
                    style={{
                      margine: 20,
                      padding: 20,
                      backgroundColor: "green",
                      borderRadius: 5,
                    }}
                  >
                    <ReactPlayer
                      style={{ borderWidth: 3 }}
                      // width={window.innerWidth >= 640 ? 640 : "80%"}
                      controls={true}
                      url={currentLess.video}
                    />
                  </div>
                  <Card
                    style={{
                      width: "40%",
                      marginLeft: 50,
                      marginTop: 0,
                      marginBottom: 0,
                      backgroundColor: "whitesmoke",
                    }}
                  >
                    <h2 style={{ color: "black" }}>Lessons</h2>
                    <div
                      style={{
                        height: 370,
                        overflowY: "scroll",
                      }}
                      className="example"
                    >
                      {lessonsList &&
                        lessonsList.map((lsn, key) => {
                          return (
                            <Card
                              style={{
                                backgroundColor: "green",
                                width: "100%",
                                height: 100,
                                color: "whitesmoke",
                                borderRadius: 0,
                                marginBottom: 5,
                                marginTop: 0,
                              }}
                              onClick={() => {
                                dispatch(selectLesson(lsn.lessonNumber));
                              }}
                            >
                              <h4>{lsn.title}</h4>
                              <p>This is lesson number-{lsn.lessonNumber}</p>
                            </Card>
                          );
                        })}
                    </div>
                  </Card>
                </div>
              ) : (
                <div style={{ width: "80%" }}>
                  <div
                    style={{
                      margine: 20,
                      padding: 20,
                      backgroundColor: "white",
                      borderRadius: 5,
                    }}
                  >
                    <ReactPlayer
                      style={{ borderWidth: 3 }}
                      width="100%"
                      controls={true}
                      url={currentLess.video}
                    />
                  </div>
                  <div style={{ width: "90%", marginLeft: 10 }}>
                    <h2 style={{ color: "white" }}>Lessons</h2>
                    <div
                      style={{
                        height: 370,
                        overflowY: "scroll",
                        borderRadius: 10,
                      }}
                      className="example"
                    >
                      {lessonsList &&
                        lessonsList.map((lsn, key) => {
                          return (
                            <Card
                              onClick={() => {
                                dispatch(selectLesson(lsn.lessonNumber));
                              }}
                            >
                              <h4>{lsn.title}</h4>
                              <p>This is lesson number-{lsn.lessonNumber}</p>
                            </Card>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}

              <Card style={{ margin: 50, width: "90%" }}>
                <AppBar
                  position="static"
                  color="black"
                  style={{ backgroundColor: "green" }}
                >
                  <Tabs
                    indicatorColor="primary"
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    <Tab
                      style={{ color: "white" }}
                      label="Description"
                      id={0}
                    />
                    <Tab style={{ color: "white" }} label="PDF's" id={1} />
                    <Tab style={{ color: "white" }} label="Quiz" id={2} />
                    <Tab style={{ color: "white" }} label="Q&A" id={3} />
                  </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>
                  {currentLess.description}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  {currentLess.pdf ? (
                    <a
                      style={{ textDecorationLine: "none" }}
                      href={currentLess.pdf}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button style={{ backgroundColor: "#52EA73" }}>
                        PDF slides
                      </Button>
                    </a>
                  ) : (
                    <Button
                      disabled={true}
                      style={{ backgroundColor: "#52EA73" }}
                    >
                      PDF slides
                    </Button>
                  )}
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  {currentLess.quiz ? (
                    <a
                      style={{ textDecorationLine: "none" }}
                      href={currentLess.quiz}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button style={{ backgroundColor: "#52EA73" }}>
                        Quiz
                      </Button>
                    </a>
                  ) : (
                    <Button
                      disabled={true}
                      style={{ backgroundColor: "#52EA73" }}
                    >
                      Quiz
                    </Button>
                  )}
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: 300,
                      overflowY: "scroll",
                    }}
                    className="example"
                  >
                    {lessonQ &&
                      lessonQ
                        .sort(
                          (a, b) =>
                            b.datePosted.toDate() - a.datePosted.toDate()
                        )
                        .map((question) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "60%",
                              }}
                            >
                              <Card
                                style={{
                                  backgroundColor: "#E3F9EB",
                                  margin: 9,
                                  textAlign: "left",
                                  padding: 7,
                                }}
                              >
                                <p>{question.question}</p>
                                <p style={{ fontSize: 9, color: "grey" }}>
                                  {moment(question.datePosted.toDate()).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </p>
                              </Card>
                              {question.answer === "" ? (
                                <div></div>
                              ) : (
                                <Card
                                  style={{
                                    backgroundColor: "#C3FFC1",
                                    margin: 9,
                                    textAlign: "right",
                                    padding: 7,
                                  }}
                                >
                                  {question.answer}
                                </Card>
                              )}
                            </div>
                          );
                        })}
                  </div>
                </TabPanel>
                {tabValue === 3 ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "60%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <CustomInput
                        labelText="Ask a question "
                        id="question"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: message,
                          onChange: (text) => {
                            inputChangeM(text);
                          },
                          type: "text",
                          multiline: true,
                        }}
                      />{" "}
                    </div>
                    <Button
                      onClick={() => {
                        sendQuestion();
                      }}
                      style={{ backgroundColor: "#52EA73", marginLeft: 30 }}
                    >
                      Send
                    </Button>
                  </div>
                ) : (
                  <div></div>
                )}
              </Card>
            </Card>
          </div>
          <Footer />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundImage: "url(" + background + ")",
        // backgroundSize: "cover",
        // backgroundPosition: "top center",
        // height: 900,
        // margin: 0,
        // padding: 0,
        // height: "100%"
        // backgroundColor: "red",
        // justifyContent: "center",
        // alignItems: "center",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          animationDuration: 2,
          animationName: "pulse",
          animationIterationCount: "infinite",
          animationDirection: "alternate",
        }}
        className="pulsate"
      >
        <h2 style={{ color: "#52EA73", marginRight: 10 }}>Loading ...</h2>
        <img src={logo} alt={""} height={40} />
      </div>
    </div>
  );
};

export default LessonPreview;
